<template>
  <div v-if="section">
    <b-tabs>
      <b-tab title="Settings" active>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Type</label>
              <select v-model="section.Type" class="form-control">
                <option value="text">Text only</option>
                <option value="icon-left">Text + icon left</option>
                <option value="icon-right">Text + icon right</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <IconInput v-model="section.Icon" label="Icon" />
          </div>
          <div class="col-md-10">
            <div class="form-group">
              <label>Label</label>
              <input v-model="section.Label" type="text" class="form-control" />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Styling">
        <div class="row">
          <div class="col-md-6">
            <ColorInput v-model="section.BackgroundColor" label="Background" />
          </div>
          <div class="col-md-6">
            <ColorInput v-model="section.BorderColor" label="Border color" />
          </div>
          <div class="col-md-6">
            <NumberInput v-model="section.Roudness" label="Corner roudness" />
          </div>
          <div class="col-md-6">
            <ColorInput v-model="section.Color" label="Color" />
          </div>
          <div class="col-md-6">
            <NumberInput v-model="section.FontSize" label="Font size" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ColorInput from './ColorInput'
import NumberInput from './NumberInput'
import IconInput from './IconInput'

export default {
  components: {
    ColorInput,
    NumberInput,
    IconInput,
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>

<style></style>
