<template>
  <div ref="iconsRef" class="form-group icon-group">
    <label>{{ label }}</label>
    <div class="icon-preview" @click="showModal">
      <span class="icon" :class="value"></span>
    </div>
    <div v-if="isShow" class="icons-modal">
      <div class="overlay" @click.prevent="close"></div>
      <div
        class="icons-box"
        :style="{ top: `${topOffset}px`, left: `${leftOffset}px` }"
      >
        <i
          v-for="icon in icons"
          :key="icon"
          :class="icon"
          @click="chooseIcon(icon)"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: [String],
      default: '',
    },
    label: {
      required: false,
      type: [String],
      default: '',
    },
  },

  data() {
    return {
      isShow: false,
      topOffset: 0,
      leftOffset: 0,
      icons: [
        'uil uil-comment',
        'uil uil-comment-edit',
        'uil uil-phone',
        'uil uil-envelope-alt',
        'uil uil-missed-call',
        'uil uil-postcard',
        'uil uil-align-center-justify',
        'uil uil-align',
        'uil uil-text',
        'uil uil-th',
        'uil uil-ruler',
        'uil uil-circle-layer',
        'uil uil-table',
        'uil uil-smile',
        'uil uil-facebook-f',
        'uil uil-instagram',
        'uil uil-linkedin',
        'uil uil-facebook',
        'uil uil-facebook-messenger',
        'uil uil-snapchat-ghost',
        'uil uil-apple',
        'uil uil-twitter',
        'uil uil-youtube',
      ],
    }
  },

  methods: {
    showModal() {
      this.isShow = true
      let offset = this.$refs.iconsRef.getBoundingClientRect()
      this.topOffset = offset.top + 70
      this.leftOffset = offset.left
    },

    close() {
      this.isShow = false
    },

    chooseIcon(icon) {
      this.$emit('input', icon)
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-group {
  position: relative;
  .icon-preview {
    cursor: pointer;
    height: 39px;
    width: 39px;
    border: 1px solid #e2e7f1;
    border-radius: 0.3rem;
    position: relative;
    .icon {
      top: 7px;
      left: 8px;
      font-size: 18px;
    }
  }
}

.icons-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  .overlay {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }
  .icons-box {
    position: absolute;
    background: #fff;
    border: 1px solid #e2e7f1;
    border-radius: 5px;
    padding: 10px;
    max-width: 235px;
    i {
      width: 26px;
      height: 26px;
      display: inline-block;
      font-size: 20px;
      margin: 8px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
